<template>
  <v-container class="py-6">
    <v-row>
      <v-col cols="12">
        <div class="box-wrapper">
          <div
            class="box-overlay"
            :class="{ open: isSidebar }"
            @click="isSidebar = !isSidebar"
          ></div>
          <div class="box-sidebar pb-3 shadow-sm" :class="{ open: isSidebar }">
            <DashbordSidebarTwo />
          </div>
          <div class="box-content">
            <div class="d-flex justify-end pa-2 d-block d-md-none">
              <v-btn icon @click="isSidebar = !isSidebar">
                <v-icon dark> mdi-format-list-bulleted-square </v-icon>
              </v-btn>
            </div>
            <div class="box-container">
              <div class="d-flex justify-space-between flex-wrap mb-5">
                <div class="d-flex align-center">
                  <v-avatar tile size="25" class="me-3">
                    <img src="@/assets/images/icons/pin_filled.svg" alt="" />
                  </v-avatar>
                  <h2 class="mb-0">Endereço de Entrega</h2>
                </div>
                <v-btn
                  outlined
                  color="primary"
                  class="text-capitalize font-600"
                  @click="dialogNovoEndereco = true"
                >
                  Novo Endereço
                </v-btn>
              </div>

              <v-data-iterator
                :items="items"
                :items-per-page.sync="itemsPerPage"
                :page.sync="page"
                hide-default-footer
              >
                <!-- <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="item in props.items"
                      :key="item.name"
                      cols="12"
                    >
                      <a href="#" class="text-decoration-none">
                        <base-card class="">
                          <div class="pa-4 d-flex align-center flex-wrap">
                            <div class="flex-1 mr-3">
                              <p class="mb-0">Ralf Edward</p>
                            </div>
                            <div class="flex-1 mr-3">
                              <p class="mb-0">
                                777 Brockton Avenue, Abington MA 2351
                              </p>
                            </div>
                            <div class="flex-1 mr-3">
                              <p class="mb-0">+1927987987498</p>
                            </div>

                            <div class="mr-3">
                              <v-btn icon color="grey" class="">
                                <v-icon>mdi-lead-pencil</v-icon>
                              </v-btn>
                              <v-btn icon color="grey" class="">
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </base-card>
                      </a>
                    </v-col>
                  </v-row>
                </template> -->

                <template v-slot:footer>
                  <v-row class="my-5 me-1" align="center" justify="center">
                    <v-spacer></v-spacer>

                    <span class="mr-4 grey--text">
                      Page {{ page }} of {{ numberOfPages }}
                    </span>
                    <v-btn
                      fab
                      :disabled="page == 1"
                      small
                      color="primary"
                      class="mr-1"
                      @click="formerPage"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      :disabled="page == numberOfPages"
                      small
                      color="primary"
                      class="ml-1"
                      @click="nextPage"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-row>
                </template>
              </v-data-iterator>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog
      scrollable
      v-model="dialogNovoEndereco"
      max-width="800"
      name="dialog-novo-endereco"
    >
      <v-card v-if="!loading">
        <v-card-title class="primary white--text">
          Novo Endereço
          <v-spacer />
          <v-btn
            icon
            text
            elevation="1"
            small
            @click="dialogNovoEndereco = false"
          >
            <v-icon class="primary white--text">close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mt-0" color="primary" />
        <v-card-text>
          <v-container>
            <!-- <h3>{{ user }}</h3> -->
            <v-row class="mt-2">
              <v-col cols="12" md="12">
                <v-text-field
                  prepend-icon="person"
                  label="Nome Próprio"
                  placeholder="Nome Próprio"
                  outlined
                  v-model="adress.name"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row name="linha-2" style="margin-top: -30px">
              <v-col cols="12" md="12">
                <v-text-field
                  prepend-icon="location_on"
                  label="Endereço"
                  placeholder="Endereço"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row name="linha-3">
              <v-col cols="12" md="6">
                <v-text-field
                  prepend-icon="phone"
                  label="Telefone principal"
                  placeholder="923 00 11 22"
                  outlined
                  v-model="adress.telefone"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  prepend-icon="email"
                  label="Email Principal"
                  outlined
                  v-model="adress.email"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            @click="dialogNovoEndereco = false"
            outlined
          >
            Sair
          </v-btn>
          <v-btn color="primary darken-1" @click="SetNovoEndereco()">
            Salvar
          </v-btn>
          <v-spacer class="hidden-sm-and-up" />
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <div class="text-center mt-12">
          <v-progress-circular size="50" indeterminate color="primary" />
          <br />
          <span class="text-center"> Atualizando... </span>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DashbordSidebarTwo from "@/components/DashboardSidebarTwo.vue";
export default {
  components: {
    DashbordSidebarTwo,
  },
  data() {
    return {
      loading: false,
      adress: {},
      dialogNovoEndereco: false,
      isSidebar: false,
      page: 1,
      itemsPerPage: 5,
      items: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      range: [0, 100, 500, 1000],
      itemsTwo: ["Low to High", "High to Low", "Date"],
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    SetNovoEndereco() {},
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    season(val) {
      return this.range[val];
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-nav-link {
  color: #2b3445 !important;
  text-decoration: none;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-left: 4px solid #fff;
  margin-bottom: 20px;
  svg {
    width: 20px;
  }
  &:hover,
  &.active {
    color: #d23f57 !important;
    border-left: 4px solid #d23f57;
  }
}
</style>
